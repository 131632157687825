.static-view {
	.inner-banner {
		&.faq-banner {
			background-image: url(RESOURCE/img/faq-banner.jpg);

		}

		&.contact-banner,
		&.agb,
		&.banner-impressum {
			background-image: url(RESOURCE/img/contact-banner.jpg);

		}
	}
}